export var POI_INTERESTS = [
  {
    no: 1,
    type: "znamenitosti",
    name: "Ljubljanski grad",
    name_en: "Ljubljana Castle",
    description:
      "Ljubljanski grad je že 900 let prepoznaven simbol mesta. Sprva je bil utrdba, nato sedež deželnega glavarja, pozneje vojašnica in celo zapor, danes pa je osrednja mestna znamenitost in privlačna kulturna točka s pestro vsebino.",
    description_en:
      "The Ljubljana Castle has been a distinctive symbol of the city for as many as 900 years. At first, it was a fort, then the seat of the regional governor, later on a barracks and even a prison, and today it is the main city attraction and a stunning cultural landmark with a rich content.",
    lat: 46.04878,
    lon: 14.50827,
  },
  {
    no: 10,
    type: "znamenitosti",
    name: "Kongresni trg",
    name_en: "Congress Square",
    description:
      "Trg so zelo temeljito preuredili za kongres Svete alianse leta 1821, po katerem je dobil tudi ime. Danes je med najpomembnejšimi trgi Ljubljane, obrobljen s Slovensko filharmonijo, ki se ponaša z zelo bogato zgodovino in sodi med najstarejše na svetu, sedežem Univerze in arhitekturnim biserom, baročno Uršulinsko cerkvijo. Poleti je trg prizorišče številnih dogodkov.",
    description_en:
      "It was thoroughly reconstructed for the Congress of the Holy Alliance in 1821, after which it was also named. Today it is one of the most important squares of Ljubljana, lined with the Slovenian Philharmonic building, the seat of the University of Ljubljana and the architectural pearl that is the Baroque Ursuline Church. In the summertime, the square hosts several events.",
    lat: 46.04995,
    lon: 14.50332,
  },
  {
    no: 11,
    type: "znamenitosti",
    name: "Čevljarski most",
    name_en: "Cobblers’ Bridge",
    description:
      "Na mestu Plečnikovega mostu, postavljenega v letih 1931 in 1932, je nekoč stal lesen most, ki je v srednjem veku povezoval dve ključni središči Ljubljane, Mestni in Novi trg. Na njem so imeli svoje delavnice čevljarji, po katerih je dobil tudi ime.",
    description_en:
      "The site of the present Cobblers’ Bridge, built by the architect Jože Plečnik between 1931 and 1932, was formerly occupied by a covered wooden bridge connecting the Mestni trg and Novi trg squares. The bridge provided space for cobblers’ workshops - hence the name Cobblers’ Bridge.",
    lat: 46.04844,
    lon: 14.50562,
  },
  {
    no: 12,
    type: "znamenitosti",
    name: "Nebotičnik",
    name_en: "Skyscraper",
    description:
      "S terase kavarne na vrhu Nebotičnika, častitljivega primerka arhitekture iz časa med obema svetovnima vojnama, se na vse strani odpirajo nekateri izmed najlepših razgledov na Ljubljano. Ljubljanski Nebotičnik, v času gradnje najvišjo stavbo na Balkanu ter deveto najvišjo v Evropi, je leta 1933 postavil arhitekt Vladimir Šubic.",
    description_en:
      "The roof terrace of the café on the top floor of Nebotičnik (Slovenian for ‘Skyscraper’), a venerable example of architecture between the two World Wars, affords some of the most beautiful views of Ljubljana opening out in all directions. Built in 1933 to designs by Vladimir Šubic, the Skyscraper was the tallest building in the Balkans and the ninth tallest building in Europe at the time of its construction.",
    lat: 46.0532,
    lon: 14.50354,
  },
  {
    no: 13,
    type: "znamenitosti",
    name: "Križanke",
    name_en: "Križanke Summer Theatre",
    description: "",
    description_en: "",
    lat: 46.04671,
    lon: 14.50319,
  },
  {
    no: 14,
    type: "znamenitosti",
    name: "Uršulinska cerkev sv.Trojice",
    name_en: "Ursuline Church of the Holy Trinity",
    description: "",
    description_en: "",
    lat: 46.04989,
    lon: 14.50211,
  },
  {
    no: 15,
    type: "znamenitosti",
    name: "Cerkev sv.Jakoba",
    name_en: "Church of St.James",
    description: "",
    description_en: "",
    lat: 46.04589,
    lon: 14.50737,
  },
  {
    no: 16,
    type: "znamenitosti",
    name: "Trg republike",
    name_en: "Republic Square",
    description:
      "Trg republike s stavbo parlamenta predstavlja politično središče Slovenije. Leta 1960 ga je zasnoval arhitekt Edvard Ravnikar. V središču trga, ki ga obdajajo starejši parlament, stolpnici TR3 ter Ljubljanska banka s Cankarjevim domom v ozadju, je obsežna ploščad.",
    description_en:
      "Trg republike (Republic Square) with the Slovenian Parliament building is the political centre of Slovenia. It was built in 1960 to a design by the architect Edvard Ravnikar. A large platform was built in the centre of the square and surrounded by the Slovenian Parliament building, the tall TR3 and Ljubljanska banka office buildings, the Cankarjev dom cultural and congress centre.",
    lat: 46.05099,
    lon: 14.50047,
  },
  {
    no: 17,
    type: "znamenitosti",
    name: "Parlament",
    name_en: "Parliament",
    description: "",
    description_en: "",
    lat: 46.05144,
    lon: 14.50116,
  },
  {
    no: 18,
    type: "znamenitosti",
    name: "Secesijska Miklošičeva ulica",
    name_en: "Art Nouveau Miklošič street",
    description:
      "Secesija se je v Ljubljani uveljavila v dobrem desetletju po uničujočem potresu leta 1895. Glavnina secesijskih stavb je bila zgrajena med ljubljansko železniško postajo ob Miklošičevi ulici in starim mestnim jedrom. Značilne so predvsem fasade stavb, še posebej njihovi ornamenti, najbolj znane stavbe pa so delo Maksa Fabianija.",
    description_en:
      "Art Nouveau was established in the city more than a decade after the devastating earthquake of 1895. The majority of the Art Nouveau buildings was constructed along the Miklošič street, between the Ljubljana railway station and the old city centre. Especially typical are the façades of the buildings, particularly their ornamentation. The most famous ones are the work of Maks Fabiani.",
    lat: 46.0527,
    lon: 14.50654,
  },
  {
    no: 19,
    type: "znamenitosti",
    name: "Pokopališče Žale",
    name_en: "Žale Cemetery",
    description: "",
    description_en: "",
    lat: 46.069,
    lon: 14.53009,
  },
  {
    no: 2,
    type: "znamenitosti",
    name: "Frančiškanska cerkev",
    name_en: "Franciscan Church",
    description: "",
    description_en: "",
    lat: 46.05175,
    lon: 14.50601,
  },
  {
    no: 20,
    type: "znamenitosti",
    name: "Arheološki park",
    name_en: "Archaeological park",
    description: "",
    description_en: "",
    lat: 46.04956,
    lon: 14.50277,
  },
  {
    no: 20,
    type: "znamenitosti",
    name: "Arheološki park",
    name_en: "Archaeological park",
    description: "",
    description_en: "",
    lat: 46.04513,
    lon: 14.50176,
  },
  {
    no: 20,
    type: "znamenitosti",
    name: "Arheološki park",
    name_en: "Archaeological park",
    description: "",
    description_en: "",
    lat: 46.04912,
    lon: 14.49877,
  },
  {
    no: "20a",
    type: "znamenitosti",
    name: "Rimski zid in rimska Ljubljana",
    name_en: "Roman Wall and Roman Ljubljana",
    description:
      "Na Mirju je skoraj v celoti ohranjena južna stranica emonskega obzidja, ki je Emono – to je bila rimska naselbina v središču današnje Ljubljane – v letih 14 in 15 našega štetja obdajalo v pravilnem pravokotniku, s šestindvajsetimi stolpi in štirimi glavnimi vrati. Rimski zid je bil v 30. letih 20. stoletja obnovljen po načrtih arhitekta Jožeta Plečnika.",
    description_en:
      "In Mirje, the southern side of the Emona walls has been preserved for almost its entire length. In 14 and 15 AD, these walls surrounded Emona, a Roman settlement that stood where the centre of Ljubljana is today, in the shape of a rectangle, and which had twenty six towers and four main doors. The Roman Wall was renovated in the 1930s according to the plans of the architect Jože Plečnik.",
    lat: 46.04563,
    lon: 14.49885,
  },
  {
    no: 21,
    type: "znamenitosti",
    name: "Živalski vrt",
    name_en: "ZOO",
    description: "",
    description_en: "",
    lat: 46.05389,
    lon: 14.47208,
  },
  {
    no: 22,
    type: "znamenitosti",
    name: "Gospodarsko razstavišče - prireditveni center Ljubljana",
    name_en: "Ljubljana Exhibition and Convention Centre",
    description: "",
    description_en: "",
    lat: 46.06125,
    lon: 14.50831,
  },
  {
    no: 23,
    type: "znamenitosti",
    name: "Narodna in univerzitetna knjižnica",
    name_en: "National and University Library",
    description:
      "Narodna in univerzitetna knjižnica, ki v svoji bogati zbirki hrani tudi številne srednjeveške rokopise, inkunabule in renesančne tiske, je bila zgrajena med letoma 1936 in 1941 in je najpomembnejše Plečnikovo delo v Sloveniji.",
    description_en:
      "The National and University Library, whose archives contain, among other things, a rich collection of medieval manuscripts, incunabula and Renaissance prints, was built between 1936 and 1941 to designs by Jože Plečnik. It is considered to be the architect’s most important work in Slovenia.",
    lat: 46.04762,
    lon: 14.50378,
  },
  {
    no: 24,
    type: "znamenitosti",
    name: "Slovenska filharmonija",
    name_en: "Slovenian Philharmonic",
    description: "",
    description_en: "",
    lat: 46.04955,
    lon: 14.50471,
  },
  {
    no: 25,
    type: "znamenitosti",
    name: "Slovenska akademija znanosti in umetnosti",
    name_en: "Slovenian Academy of Sciences and Arts",
    description: "",
    description_en: "",
    lat: 46.04758,
    lon: 14.50448,
  },
  {
    no: 26,
    type: "znamenitosti",
    name: "Univerza v Ljubljani",
    name_en: "University of Ljubljana",
    description: "",
    description_en: "",
    lat: 46.04919,
    lon: 14.50417,
  },
  {
    no: 27,
    type: "znamenitosti",
    name: "Cankarjev dom - kulturni in kongresni center",
    name_en: "Cankarjev dom - Cultural and Congress Centre",
    description: "",
    description_en: "",
    lat: 46.04988,
    lon: 14.49932,
  },
  {
    no: 28,
    type: "znamenitosti",
    name: "Center urbane kulture Kino Šiška",
    name_en: "Kino Šiška Centre for Urban Culture",
    description: "",
    description_en: "",
    lat: 46.06899,
    lon: 14.48896,
  },
  {
    no: 29,
    type: "znamenitosti",
    name: "Trubarjeva hiša literature",
    name_en: "Trubar’s House of Literature",
    description: "",
    description_en: "",
    lat: 46.05057,
    lon: 14.50643,
  },
  {
    no: 3,
    type: "znamenitosti",
    name: "Tromostovje",
    name_en: "Triple Bridge",
    description:
      "Je ljubljanska arhitektonska posebnost. Sestavljeno je iz starega, kamnitega mostu iz leta 1842 in še dveh, ki ju je dodal arhitekt Jože Plečnik leta 1931. Skupaj odpirajo vhod v staro mestno jedro.",
    description_en:
      "The Triple Bridge, a special architectural feature of Ljubljana, is made up of the old stone bridge from 1842 and two more bridges which architect Jože Plečnik added in 1931, thus providing an elegant entrance into the Old Town of Ljubljana.",
    lat: 46.05111,
    lon: 14.50623,
  },
  {
    no: 30,
    type: "znamenitosti",
    name: "Botanični vrt",
    name_en: "Ljubljana Botanic Garden",
    description:
      "Botanični vrt v Ljubljani spada med najstarejše botanične vrtove v Evropi. Ustanovljen je bil že leta 1810, v času Napoleonovih Ilirskih provinc. V tej zeleni oazi, ki velja za zeleno dušo Ljubljane, lahko posedite tudi v prijetni čajnici Primula.",
    description_en:
      "The Botanical Garden in Ljubljana is one of the oldest of its kind in Europe. It was established as early as 1810, at the time of Napoleon’s Illyrian Provinces. In this verdant oasis, also considered the green soul of Ljubljana, you can relax in the attractive Primula tearoom.",
    lat: 46.04117,
    lon: 14.51448,
  },
  {
    no: 31,
    type: "znamenitosti",
    name: "AKC Metelkova mesto",
    name_en: "Autonomous Cultural Centre Metelkova mesto",
    description:
      "Metelkova je ena najbolj znanih posebnosti Ljubljane. Središče alternativne kulture, ki je nastalo iz skvota v nekdanji vojašnici, združuje raznovrstne ustvarjalne prakse in dogajanja.",
    description_en:
      "Metelkova mesto, an Autonomus Cultural Centre that developed from a squat in a former army barracks and became one of the best known attractions of Ljubljana, brings together a variety of different artistic practices and events.",
    lat: 46.05692,
    lon: 14.51621,
  },
  {
    no: 32,
    type: "znamenitosti",
    name: "Foto točka WOW",
    name_en: "WOW photo point",
    description: "",
    description_en: "",
    lat: 46.05089,
    lon: 14.5105,
  },
  {
    no: 4,
    type: "znamenitosti",
    name: "Prešernov trg",
    name_en: "Prešeren Square",
    description:
      "Je imenovan po največjem slovenskem pesniku Francetu Prešernu (1800-1849). Njegova pesem Zdravljica je slovenska himna. Poleg spomenika pesniku sta za trg značilni secesijski fasadi “Hauptmannove hiše” in “Galerije Emporium” ter po italijanskih vzorih oblikovana fasada Frančiškanske cerkve. Za domačine je Prešernov trg srce mesta.",
    description_en:
      "The square is named after the greatest Slovenian poet, France Prešeren (1800-1849). His poems, Zdravljica/A Toast is the Slovenian anthem. Beside the Prešeren monument special features of the square are the Art Nouveau facades of the “Hauptmann” and “Galerija Emporium” buildings as well as that of the 17th century Franciscan Church, designed on the Italian model. For the locals, the Prešeren  Square is the heart of the city where everyone meets.",
    lat: 46.05145,
    lon: 14.50606,
  },
  {
    no: 5,
    type: "znamenitosti",
    name: "Mestna hiša",
    name_en: "Town Hall",
    description:
      "Prva stavbo, zgrajeno na tem mestu leta 1484, so leta 1718 prezidali po načrtih arhitekta Gregorja Mačka. Hrani številne spomine na ljubljansko preteklost, med drugim Herkulov in Narcisov vodnjak. Danes je tu sedež Mestne občine Ljubljana. Blizu stavbe, na začetku Mestnega trga, stoji znameniti vodnjak kranjskih rek (F. Robba, 1751).",
    description_en:
      "The first building erected on this site in 1484 was rebuilt in 1718 to the plans of architect Gregor Maček. It has preserved a number of memorials to Ljubljana’s past, including the Hercules and Narcissus fountains. Today it is the seat of the Ljubljana Municipality. Close by stands the famous Fountain of the Three Carniolan Rivers (F. Robba, 1751).",
    lat: 46.04988,
    lon: 14.50702,
  },
  {
    no: 6,
    type: "znamenitosti",
    name: "Glavna tržnica",
    name_en: "Central Market",
    description:
      "Je kraj kjer se Ljubljančani srečujejo in družijo. Glavna tržnica obsega tržnico na prostem, pokrito tržnico ter niza majhnih trgovinic ob reki Ljubljanici, ki jih imenujemo Plečnikove pokrite tržnice, saj se nahajajo v kolonadah, ki jih je zasnoval sloviti arhitekt Jože Plečnik.",
    description_en:
      "It is a place for the locals to meet and enjoy themselves together. The Central Market consists of an open-air market, a covered market and a series of small food shops along the river Ljubljanica, which are referred to as ‘Plečnik’s Covered Market’ as they are located in a colonnade designed by Ljubljana’s famous architect Jože Plečnik.",
    lat: 46.05127,
    lon: 14.50935,
  },
  {
    no: 7,
    type: "znamenitosti",
    name: "Stolnica sv.Nikolaja",
    name_en: "Cathedral (Church of St.Nicholas)",
    description:
      "Današnja cerkev je bila sezidana med leti 1701 in 1708 po načrtih rimskega arhitekta Andrea Pozza in okrašena s freskami Giulia Quaglia. Nova bronasta vrata v stolnico je l. 1996 izdelal kipar Tone Demšar ob 1250-letnici Krščanstva na Slovenskem. Stranska vrata, ki upodabljajo zgodovino ljubljanske škofije, so delo kiparja Mirsada Begića.",
    description_en:
      "The cathedral, with frescoes by Giulio Quaglio and built to the plans of the Roman Jesuit Andrea Pozzo, was erected between 1701 and 1708. The new main bronze door was created in 1996 by sculptor Tone Demšar to mark 1250 years of Christianity in our country, while sculptor Mirsad Begić carved the history of the Ljubljana Diocese on the side door.",
    lat: 46.05076,
    lon: 14.50815,
  },
  {
    no: 8,
    type: "znamenitosti",
    name: "Zmajski most",
    name_en: "Dragon Bridge",
    description:
      "Zmajski most, ki ga krasijo znameniti kipi zmajev, je najbolj znana podoba Ljubljane. Velja za izjemen tehnični spomenik ter za vrhunsko stvaritev secesijske arhitekture s preloma 19. in 20. stoletja. Zgrajen je bil v letih 1900 in 1901 po načrtih arhitekta Jurija Zaninovića in je bil prva prava železobetonska konstrukcija v mestu.",
    description_en:
      "The Dragon Bridge, adorned with its famous dragon statues, appears in the most recognizable images of Ljubljana. The bridge is a unique creation considered to be an extraordinary piece of technical heritage and a superb example of Art Nouveau architecture. Constructed between 1900 and 1901 and designed by the architect Jurij Zaninović, it was Ljubljana’s first reinforced concrete structure.",
    lat: 46.0518,
    lon: 14.51098,
  },
  {
    no: 9,
    type: "znamenitosti",
    name: "Mesarski most",
    name_en: "Butchers’ Bridge",
    description:
      "Mesarski most je ljubljanski most ljubezni, zgrajen leta 2010. Nešteti zaljubljenci so na njem s ključavnicami simbolično zaklenili svojo ljubezen in nato ključe odvrgli v reko Ljubljanico, ki teče pod njim. Njegova zasnova je deloma umetniška, saj most zaznamujejo kipi priznanega sodobnega slovenskega kiparja Jakova Brdarja.",
    description_en:
      "The Butchers’ Bridge is Ljubljana’s bridge of love, constructed in 2010. Countless lovers have symbolically padlocked their love on it and dropped the keys into the Ljubljanica River, which flows underneath. Its design is artistically-oriented to some extent, as the bridge is adorned with statues by the renowned Slovenian sculptor Jakov Brdar.",
    lat: 46.05172,
    lon: 14.50876,
  },
];
