export var POI_PARKS = [
  {
    no: 1,
    type: "športni parki",
    name: "Center Stožice / Stožice Sports Centre",
    lat: 46.08109,
    lon: 14.52267,
  },
  {
    no: 10,
    type: "športni parki",
    name: "Športni center Triglav / Triglav Sports Centre",
    lat: 46.06882,
    lon: 14.50595,
  },
  {
    no: 11,
    type: "športni parki",
    name: "Mostec – rekreacijski center / Mostec - Recreation Centre",
    lat: 46.06391,
    lon: 14.47465,
  },
  {
    no: 12,
    type: "športni parki",
    name: "Gimnastični center Ljubljana / Gymnastics Centre Ljubljana",
    lat: 46.03826,
    lon: 14.48354,
  },
  {
    no: 13,
    type: "športni parki",
    name: "Woop! Ljubljana trampolin park / Woop! Trampoline park Ljubljana",
    lat: 46.06724,
    lon: 14.55318,
  },
  {
    no: 2,
    type: "športni parki",
    name: "Športni park Tivoli / Tivoli Sports Park",
    lat: 46.05842,
    lon: 14.49713,
  },
  {
    no: 3,
    type: "športni parki",
    name: "Športni park Kodeljevo / Kodeljevo Sports Park",
    lat: 46.04969,
    lon: 14.533,
  },
  {
    no: 4,
    type: "športni parki",
    name: "Športni park Ljubljana / Ljubljana Sports Park",
    lat: 46.06976,
    lon: 14.49951,
  },
  {
    no: 5,
    type: "športni parki",
    name: "Golf Trnovo Ljubljana / Golf Trnovo Ljubljana",
    lat: 46.03032,
    lon: 14.47398,
  },
  {
    no: 6,
    type: "športni parki",
    name: "Vodno mesto Atlantis / Atlantis Water Park",
    lat: 46.06311,
    lon: 14.54887,
  },
  {
    no: 7,
    type: "športni parki",
    name: "Laguna - Mestna plaža Ljubljana / Laguna - Water Park Ljubljana",
    lat: 46.09676,
    lon: 14.51968,
  },
  {
    no: 8,
    type: "športni parki",
    name: "RIC Sava Tomačevo / RIC Sava Tomačevo Sports Park",
    lat: 46.08231,
    lon: 14.54343,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.07517,
    lon: 14.5278,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.08237,
    lon: 14.50054,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.06044,
    lon: 14.45882,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.0444,
    lon: 14.46681,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.0327,
    lon: 14.49416,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.03242,
    lon: 14.54612,
  },
  {
    no: 9,
    type: "športni parki",
    name:
      "Pot spominov in tovarištva - Zeleni obroč / Path of Remembrance and Comradeship - Green Ring",
    lat: 46.06766,
    lon: 14.55357,
  },
];
