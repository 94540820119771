export var POI_MUSEUMS = [
  {
    no: 1,
    type: "muzeji galerije",
    name: "Mestni muzej Ljubljana",
    name_en: "City Museum of Ljubljana – MGML",
    description:
      "Mestni muzej Ljubljana domuje v Turjaški palači, arhitekturnem spomeniku, ki hrani večtisočletno kulturno dediščino ljubljanskega prostora - od 4500 stare prazgodovinske koliščarske naselbine do današnje privlačne prestolnice Slovenije, od antičnega mesta Emona do sedanjega gospodarskega, političnega, upravnega in ustvarjalnega središča.",
    description_en:
      "Visitors to the City Museum can admire the Renaissance architectural features of the elegant Auersperg Palace, which houses the institution and is an architectural monument in itself. The museum holds Ljubljana’s cultural heritage that ranges from the 4,500-year-old prehistoric pile dwelling settlement to the present-day charming capital of Slovenia.",
    lat: 46.04677,
    lon: 14.50469,
  },
  {
    no: 10,
    type: "muzeji galerije",
    name: "Muzej novejše zgodovine Slovenije",
    name_en: "National Museum of Contemporary History",
    description: "",
    description_en: "",
    lat: 46.05903,
    lon: 14.49531,
  },
  {
    no: 11,
    type: "muzeji galerije",
    name: "Železniški muzej",
    name_en: "Railway Museum",
    description: "",
    description_en: "",
    lat: 46.06263,
    lon: 14.50262,
  },
  {
    no: 12,
    type: "muzeji galerije",
    name: "Muzej za arhitekturo in oblikovanje",
    name_en: "Museum of Architecture and Design",
    description: "",
    description_en: "",
    lat: 46.05048,
    lon: 14.56352,
  },
  {
    no: 13,
    type: "muzeji galerije",
    name: "Slovenski etnografski muzej",
    name_en: "Slovene Ethnographic Museum",
    description: "",
    description_en: "",
    lat: 46.05495,
    lon: 14.51613,
  },
  {
    no: 14,
    type: "muzeji galerije",
    name: "Narodni muzej Slovenije – Metelkova",
    name_en: "National Museum of Slovenia – Metelkova",
    description: "",
    description_en: "",
    lat: 46.05521,
    lon: 14.51687,
  },
  {
    no: 15,
    type: "muzeji galerije",
    name: "Prirodoslovni muzej Slovenije",
    name_en: "Slovenian Museum of Natural History",
    description: "",
    description_en: "",
    lat: 46.05201,
    lon: 14.49928,
  },
  {
    no: 16,
    type: "muzeji galerije",
    name: "Muzej sodobne umetnosti Metelkova – MSUM",
    name_en: "Museum of Contemporary Art Metelkova",
    description: "",
    description_en: "",
    lat: 46.05588,
    lon: 14.51705,
  },
  {
    no: 17,
    type: "muzeji galerije",
    name: "Hiša eksperimentov",
    name_en: "House of Experiments",
    description: "",
    description_en: "",
    lat: 46.05258,
    lon: 14.51091,
  },
  {
    no: 18,
    type: "muzeji galerije",
    name: "Slovenski šolski muzej",
    name_en: "Slovenian School Museum",
    description: "",
    description_en: "",
    lat: 46.05025,
    lon: 14.50233,
  },
  {
    no: 19,
    type: "muzeji galerije",
    name: "Galerija na prostem Jakopičevo sprehajališče Tivoli",
    name_en: "Outdoor Gallery Jakopič Promenade",
    description:
      "Tivoli je največji in najlepši ljubljanski park, ki sega v samo središče mesta. Park je med letoma 1921 in 1939 preuredil Jože Plečnik, ki je zasnoval široko Jakopičevo sprehajališče, ki se konča pred stopniščem pod Tivolskim gradom (sedež Mednarodnega grafičnega likovnega centra) in je danes znano tudi po razstavah fotografij na prostem.",
    description_en:
      "Tivoli Park is Ljubljana’s largest and most beautiful park, stretching right into the city centre. Tivoli Park’s scenic Jakopič Promenade (Jakopičevo sprehajališče), designed by the architect Jože Plečnik which ends in front of the Tivoli Mansion (International Centre of Graphic Art), has become a well known outdoor venue for exhibitions of photographs.",
    lat: 46.05403,
    lon: 14.49568,
  },
  {
    no: 2,
    type: "muzeji galerije",
    name: "Mestna galerija Ljubljana",
    name_en: "City Art Gallery",
    description: "",
    description_en: "",
    lat: 46.04945,
    lon: 14.50666,
  },
  {
    no: 20,
    type: "muzeji galerije",
    name: "Galerija na prostem Krakovski nasip",
    name_en: "Outdoor Gallery Krakovski nasip Embankment",
    description: "",
    description_en: "",
    lat: 46.04506,
    lon: 14.50538,
  },
  {
    no: 21,
    type: "muzeji galerije",
    name: "Tobačni muzej",
    name_en: "Tobacco Museum",
    description: "",
    description_en: "",
    lat: 46.04766,
    lon: 14.49296,
  },
  {
    no: 22,
    type: "muzeji galerije",
    name: "Union Doživetje",
    name_en: "Union Experience (Brewery)",
    description: "",
    description_en: "",
    lat: 46.06017,
    lon: 14.50047,
  },
  {
    no: 23,
    type: "muzeji galerije",
    name: "Muzej iluzij",
    name_en: "Museum of Illusions",
    description: "",
    description_en: "",
    lat: 46.04961,
    lon: 14.50335,
  },
  {
    no: 24,
    type: "muzeji galerije",
    name: "Judovski muzej Ljubljana",
    name_en: "Jewish Museum Ljubljana",
    description: "",
    description_en: "",
    lat: 46.04625,
    lon: 14.50423,
  },
  {
    no: 3,
    type: "muzeji galerije",
    name: "Galerija Jakopič",
    name_en: "Jakopič Gallery",
    description: "",
    description_en: "",
    lat: 46.04782,
    lon: 14.50113,
  },
  {
    no: 4,
    type: "muzeji galerije",
    name: "Mednarodni grafični likovni center",
    name_en: "International Centre of Graphic Arts",
    description: "",
    description_en: "",
    lat: 46.05458,
    lon: 14.49294,
  },
  {
    no: 5,
    type: "muzeji galerije",
    name: "Ustvarjalni center Švicarija",
    name_en: "Švicarija Creative Centre",
    description: "",
    description_en: "",
    lat: 46.05532,
    lon: 14.49213,
  },
  {
    no: 6,
    type: "muzeji galerije",
    name: "Plečnikova hiša",
    name_en: "Plečnik House",
    description: "",
    description_en: "",
    lat: 46.04241,
    lon: 14.50224,
  },
  {
    no: 7,
    type: "muzeji galerije",
    name: "Narodni muzej Slovenije",
    name_en: "National Museum of Slovenia",
    description:
      "Osrednji slovenski zgodovinski muzej hrani številne predmete z območja Slovenije, ki sodijo v sam vrh svetovne kulturne dediščine. Med njimi gotovo izstopa 60.000 let stara neandertalčeva piščal iz Divjih Bab.",
    description_en:
      "Being Slovenia’s main museum of national history, the National Museum keeps a number of finds from Slovenia considered to be important treasures of the world’s cultural heritage. One of the most outstanding is the 60,000 year old Neanderthal flute from the Divje Babe excavation site.",
    lat: 46.05194,
    lon: 14.49967,
  },
  {
    no: 8,
    type: "muzeji galerije",
    name: "Narodna galerija",
    name_en: "National Gallery of Slovenia",
    description:
      "Narodna galerija je osrednja slovenska galerija za starejšo umetnost in hrani največjo zbirko likovnih del od visokega srednjega veka do 20. stoletja. Na ogled so še stalna razstava del enega vodilnih evropskih modernističnih slikarjev 20. stoletja, Zorana Mušiča (1909–2005) ter občasne razstave.",
    description_en:
      "The National Gallery, Slovenia’s foremost museum of historical art, holds the country’s largest collection of fine art from the High Middle Ages to the 20th century. It also houses a permanent exhibition of works by Zoran Mušič (1909–2005), one of Europe’s leading modernist painters, and various temporary exhibitions.",
    lat: 46.05346,
    lon: 14.49998,
  },
  {
    no: 9,
    type: "muzeji galerije",
    name: "Moderna galerija",
    name_en: "Museum of Modern Art",
    description:
      "Moderna galerija Ljubljana je muzej slovenske moderne umetnosti. Na ogled ponuja stalno zbirko slovenske umetnosti 20. stoletja, poleg tega pa v njej potekajo tudi občasne razstave, na katerih se predstavljajo nove umetniške prakse.",
    description_en:
      "The Ljubljana Museum of Modern Art is dedicated to modern art in Slovenia. Apart from housing a permanent collection of 20th century Slovenian art, it hosts temporary exhibitions presenting new artistic practices.",
    lat: 46.05311,
    lon: 14.49881,
  },
];
