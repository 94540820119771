export var POI_ACCOMODATIONS = [
  {
    no: 1,
    type: "namestitev",
    name: "Intercontinental *****",
    lat: 46.05722,
    lon: 14.50555,
  },
  {
    no: 2,
    type: "namestitev",
    name: "Allegro Hotel ****",
    lat: 46.04654,
    lon: 14.50737,
  },
  {
    no: 3,
    type: "namestitev",
    name: "Antiq Palace Hotel & Spa ****",
    lat: 46.0481,
    lon: 14.50404,
  },
  {
    no: 4,
    type: "namestitev",
    name: "Austria Trend Hotel Ljubljana ****",
    lat: 46.08188,
    lon: 14.51376,
  },
  {
    no: 5,
    type: "namestitev",
    name: "Best Western Premier Hotel Slon ****",
    lat: 46.05254,
    lon: 14.50384,
  },
  {
    no: 6,
    type: "namestitev",
    name: "Central Hotel ****",
    lat: 46.05573,
    lon: 14.50738,
  },
  {
    no: 7,
    type: "namestitev",
    name: "Four Points by Sheraton Ljubljana Mons ****",
    lat: 46.05209,
    lon: 14.45257,
  },
  {
    no: 8,
    type: "namestitev",
    name: "G Design Hotel ****",
    lat: 46.03308,
    lon: 14.45175,
  },
  {
    no: 9,
    type: "namestitev",
    name: "Grand Hotel Union ****",
    lat: 46.05259,
    lon: 14.50636,
  },
  {
    no: 10,
    type: "namestitev",
    name: "Hotel Cubo ****",
    lat: 46.04922,
    lon: 14.50188,
  },
  {
    no: 11,
    type: "namestitev",
    name: "Hotel Galleria ****",
    lat: 46.04695,
    lon: 14.50675,
  },
  {
    no: 12,
    type: "namestitev",
    name: "Hotel Gloria ****",
    lat: 46.08678,
    lon: 14.51358,
  },
  {
    no: 13,
    type: "namestitev",
    name: "Hotel Lev ****",
    lat: 46.05624,
    lon: 14.50237,
  },
  {
    no: 14,
    type: "namestitev",
    name: "Lesar Hotel Angel ****",
    lat: 46.04686,
    lon: 14.50711,
  },
  {
    no: 15,
    type: "namestitev",
    name: "M Hotel ****",
    lat: 46.06872,
    lon: 14.4889,
  },
  {
    no: 16,
    type: "namestitev",
    name: "Nox Hotel ****",
    lat: 46.09909,
    lon: 14.46013,
  },
  {
    no: 17,
    type: "namestitev",
    name: "Radisson Blu Plaza Hotel Ljubljana ****",
    lat: 46.06653,
    lon: 14.55093,
  },
  {
    no: 18,
    type: "namestitev",
    name: "The Fuzzy Log ****",
    lat: 46.05574,
    lon: 14.50735,
  },
  {
    no: 19,
    type: "namestitev",
    name: "uHotel ****",
    lat: 46.05327,
    lon: 14.50592,
  },
  {
    no: 20,
    type: "namestitev",
    name: "Urban Ring Ljubljana ****",
    lat: 46.03067,
    lon: 14.53144,
  },
  {
    no: 21,
    type: "namestitev",
    name: "Vander Hotel ****",
    lat: 46.04962,
    lon: 14.50589,
  },
  {
    no: 22,
    type: "namestitev",
    name: "Zlata ladjica *****+",
    lat: 46.04825,
    lon: 14.5053,
  },
  {
    no: 23,
    type: "namestitev",
    name: "Adora Hotel ***",
    lat: "46.045l87",
    lon: 14.50795,
  },
  {
    no: 24,
    type: "namestitev",
    name: "Ahotel ***",
    lat: 46.03034,
    lon: 14.49196,
  },
  {
    no: 25,
    type: "namestitev",
    name: "Art Hotel ***",
    lat: 46.04818,
    lon: 14.50241,
  },
  {
    no: 26,
    type: "namestitev",
    name: "Atelier Hotel ***",
    lat: 46.05691,
    lon: 14.50329,
  },
  {
    no: 27,
    type: "namestitev",
    name: "B&B Hotel Ljubljana Park ***",
    lat: 46.05363,
    lon: 14.51486,
  },
  {
    no: 28,
    type: "namestitev",
    name: "City Hotel Ljubljana ***",
    lat: 46.05387,
    lon: 14.50812,
  },
  {
    no: 29,
    type: "namestitev",
    name: "Emonec Hotel ***",
    lat: 46.05075,
    lon: 14.505,
  },
  {
    no: 30,
    type: "namestitev",
    name: "Holiday Inn Express Ljubljana ***",
    lat: 46.07265,
    lon: 14.50351,
  },
  {
    no: 31,
    type: "namestitev",
    name: "Hotel Asteria ***",
    lat: 46.05644,
    lon: 14.5193,
  },
  {
    no: 32,
    type: "namestitev",
    name: "Hotel Florjančkov hram ***",
    lat: 46.09389,
    lon: 14.5159,
  },
  {
    no: 33,
    type: "namestitev",
    name: "Hotel Katrca ***",
    lat: 46.05063,
    lon: 14.4855,
  },
  {
    no: 34,
    type: "namestitev",
    name: "Hotel Mrak ***",
    lat: 46.0473,
    lon: 14.50197,
  },
  {
    no: 35,
    type: "namestitev",
    name: "Hotel Stil ***",
    lat: 46.0444,
    lon: 14.56755,
  },
  {
    no: 36,
    type: "namestitev",
    name: "Hotel Vila Katrca ***",
    lat: 46.04494,
    lon: 14.48621,
  },
  {
    no: 37,
    type: "namestitev",
    name: "Ljubljana Resort ***",
    lat: 46.09878,
    lon: 14.51815,
  },
  {
    no: 38,
    type: "namestitev",
    name: "Sport Hotel Ljubljana ***",
    lat: 46.039,
    lon: 14.48333,
  },
  {
    no: 39,
    type: "namestitev",
    name: "Urban Hotel ***",
    lat: 46.05366,
    lon: 14.50389,
  },
  {
    no: 40,
    type: "namestitev",
    name: "WUD Hotel ***",
    lat: 46.0506,
    lon: 14.54827,
  },
  {
    no: 41,
    type: "namestitev",
    name: "Bit Center Hotel **",
    lat: 46.04854,
    lon: 14.54883,
  },
  {
    no: 42,
    type: "namestitev",
    name: "Hotel Morea **",
    lat: 46.09887,
    lon: 14.46047,
  },
  {
    no: 43,
    type: "namestitev",
    name: "Aladin Hostel **",
    lat: 46.07104,
    lon: 14.48448,
  },
  {
    no: 44,
    type: "namestitev",
    name: "Ana Hostel",
    lat: 46.05365,
    lon: 14.50941,
  },
  {
    no: 45,
    type: "namestitev",
    name: "Fluxus Hostel **",
    lat: 46.05201,
    lon: 14.5025,
  },
  {
    no: 46,
    type: "namestitev",
    name: "Ginger Rooms ***",
    lat: 46.05304,
    lon: 14.50688,
  },
  {
    no: 47,
    type: "namestitev",
    name: "H2O Hostel **",
    lat: 46.05221,
    lon: 14.51189,
  },
  {
    no: 48,
    type: "namestitev",
    name: "Hostel 24",
    lat: 46.0506,
    lon: 14.51354,
  },
  {
    no: 49,
    type: "namestitev",
    name: "Hostel 24/1",
    lat: 46.07205,
    lon: 14.48816,
  },
  {
    no: 50,
    type: "namestitev",
    name: "Hostel 24/2",
    lat: 46.08046,
    lon: 14.51705,
  },
  {
    no: 51,
    type: "namestitev",
    name: "Hostel Ad Hoc",
    lat: 46.04882,
    lon: 14.50591,
  },
  {
    no: 52,
    type: "namestitev",
    name: "Hostel Ava",
    lat: 46.05191,
    lon: 14.50683,
  },
  {
    no: 53,
    type: "namestitev",
    name: "Hostel Celica *",
    lat: 46.05656,
    lon: 14.51654,
  },
  {
    no: 54,
    type: "namestitev",
    name: "Hostel Tivoli",
    lat: 46.06244,
    lon: 14.49636,
  },
  {
    no: 55,
    type: "namestitev",
    name: "Hostel Vila Veselova **",
    lat: 46.05108,
    lon: 14.4965,
  },
  {
    no: 56,
    type: "namestitev",
    name: "Hostel Vrba",
    lat: 46.04367,
    lon: 14.50341,
  },
  {
    no: 57,
    type: "namestitev",
    name: "MS Sparrow Hostel",
    lat: 46.07365,
    lon: 14.5107,
  },
  {
    no: 58,
    type: "namestitev",
    name: "Sax Hostel",
    lat: 46.04317,
    lon: 14.50461,
  },
  {
    no: 59,
    type: "namestitev",
    name: "Tresor Hostel",
    lat: 46.05222,
    lon: 14.50419,
  },
  {
    no: 60,
    type: "namestitev",
    name: "Youth Hostel Ljubljana *",
    lat: 46.04854,
    lon: 14.54937,
  },
  {
    no: 61,
    type: "namestitev",
    name: "Zeppelin Hostel *",
    lat: 46.05517,
    lon: 14.50446,
  },
  {
    no: 62,
    type: "namestitev",
    name: "Aparthotel Vila Minka ***",
    lat: 46.07345,
    lon: 14.46828,
  },
  {
    no: 63,
    type: "namestitev",
    name: "B&B Slamič ***",
    lat: 46.05546,
    lon: 14.50349,
  },
  {
    no: 64,
    type: "namestitev",
    name: "B&B Striček ***",
    lat: 46.06966,
    lon: 14.53951,
  },
  {
    no: 65,
    type: "namestitev",
    name: "Dvor Tacen ****",
    lat: 46.09795,
    lon: 14.46234,
  },
  {
    no: 66,
    type: "namestitev",
    name: "Gostišče Kubus ***",
    lat: 46.03025,
    lon: 14.5189,
  },
  {
    no: 67,
    type: "namestitev",
    name: "Hiša pod Gradom ****",
    lat: 46.04939,
    lon: 14.51132,
  },
  {
    no: 68,
    type: "namestitev",
    name: "Park Žibert *",
    lat: 46.08875,
    lon: 14.47241,
  },
  {
    no: 69,
    type: "namestitev",
    name: "Penzion Kmečki hram ***",
    lat: 46.07894,
    lon: 14.53641,
  },
  {
    no: 70,
    type: "namestitev",
    name: "Penzion Tavčar ***",
    lat: 46.07513,
    lon: 14.55954,
  },
  {
    no: 71,
    type: "namestitev",
    name: "Portal ***",
    lat: 46.05578,
    lon: 14.54648,
  },
  {
    no: 72,
    type: "namestitev",
    name: "Pri Vodniku ***",
    lat: 46.06705,
    lon: 14.48601,
  },
  {
    no: 73,
    type: "namestitev",
    name: "Pri Žabarju ***",
    lat: 46.0424,
    lon: 14.46871,
  },
  {
    no: 74,
    type: "namestitev",
    name: "Stari Tišler ***",
    lat: 46.05562,
    lon: 14.50946,
  },
  {
    no: 75,
    type: "namestitev",
    name: "Ljubljana Resort ****",
    lat: 46.09756,
    lon: 14.51907,
  },
];
