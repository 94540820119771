import GeoJSON from "ol/format/GeoJSON";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { POI_INTERESTS } from "../data/poi/interests";
import { POI_ACCOMODATIONS } from "../data/poi/accomodations";
import { POI_MUSEUMS } from "../data/poi/museums";
import { POI_PARKS } from "../data/poi/parks";

const SIMPLIFY_TOLERANCE = 10;

const format = new GeoJSON({ featureProjection: "EPSG:3857" });

/**
 * Export features to base64 string.
 * @param {*} features Drawing features.
 */
export function exportDraw(features) {
  for (let f of features) {
    let sf = f.getGeometry().simplify(SIMPLIFY_TOLERANCE);
    f.setGeometry(sf);
  }

  const json = format.writeFeaturesObject(features);

  // Extract only coordinates
  let lines = [];
  for (let f of json.features) {
    let coords = f.geometry.coordinates;

    for (let i in coords) {
      coords[i] = simplifyCoordinates(coords[i]);
    }

    lines.push(coords);
  }

  let b64String = btoa(JSON.stringify(lines));

  return b64String;
}

function simplifyCoordinates(coord) {
  let x = String(coord[0]);
  let y = String(coord[1]);

  x = x.substring(3, 8);
  y = y.substring(3, 8);

  x = parseInt(x);
  y = parseInt(y);

  return [x, y];
}

/**
 * Import features from base64 string.
 * @param {*} b64String Base64 string.
 */
export function importDraw(b64String) {
  var lines = JSON.parse(atob(b64String));

  // Create objects
  let objects = [];
  let features = {
    type: "FeatureCollection",
    features: objects,
  };

  for (let line of lines) {
    for (let i in line) {
      line[i] = unsimplifyCoordinates(line[i]);
    }

    let feature = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: line,
      },
      properties: null,
    };
    objects.push(feature);
  }

  return format.readFeatures(features);
}

function unsimplifyCoordinates(coord) {
  let x = coord[0];
  let y = coord[1];

  x = x / 100000 + 14;
  y = y / 100000 + 46;

  return [x, y];
}

export function importPOI() {
  var poiFeatures = [];

  for (let p of POI_INTERESTS) {
    let pFeature = getPOIFeature(p);
    pFeature.setStyle(getPOIStyle("#d71a21"));
    poiFeatures.push(pFeature);
  }

  for (let p of POI_ACCOMODATIONS) {
    let pFeature = getPOIFeature(p);
    pFeature.setStyle(getPOIStyle("#034ea2"));
    poiFeatures.push(pFeature);
  }

  for (let p of POI_MUSEUMS) {
    let pFeature = getPOIFeature(p);
    pFeature.setStyle(getPOIStyle("#a05d2c"));
    poiFeatures.push(pFeature);
  }

  for (let p of POI_PARKS) {
    let pFeature = getPOIFeature(p);
    pFeature.setStyle(getPOIStyle("#59a156"));
    poiFeatures.push(pFeature);
  }

  return poiFeatures;
}

function getPOIFeature(poi) {
  return new Feature({
    geometry: new Point(fromLonLat([poi.lon, poi.lat])),
    name: poi.name,
    name_en: poi.name_en,
    description: poi.description,
    description_en: poi.description_en,
  });
}

function getPOIStyle(color) {
  return new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: "#FFFFFF",
        width: 2,
      }),
    }),
  });
}
