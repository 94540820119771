import Draw from "ol/interaction/Draw";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import GeometryType from "ol/geom/GeometryType";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import Overlay from "ol/Overlay";
import XYZ from "ol/source/XYZ";
import Point from "ol/geom/Point";
import { Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";
import { Attribution, Control, defaults as defaultControls } from "ol/control";
import { transformExtent } from "ol/proj";
import QRCode from "qrcode";
import { exportDraw, importDraw, importPOI } from "./importExport";

export function initMap(drawData) {
  var mainColor = "#2E7CF6";
  var drawing = false;

  // Layers
  function transform(extent) {
    return transformExtent(extent, "EPSG:4326", "EPSG:3857");
  }
  var tileLayer = new TileLayer({
    extent: transform([14.45153, 46.02977, 14.56737, 46.09986]),
    source: new XYZ({
      attributions: "TURIZEM LJUBLJANA | © KARTOGRAFIJA",
      url: "./data/tiles/{z}/{x}/{y}.png",
      tileSize: 256,
    }),
  });

  var tileCenterLayer = new TileLayer({
    minZoom: 15,
    extent: transform([14.491377, 46.040551, 14.521373, 46.061721]),
    source: new XYZ({
      attributions: "TURIZEM LJUBLJANA | © KARTOGRAFIJA",
      url: "./data/tiles-center/{z}/{x}/{y}.png",
      tileSize: 256,
    }),
  });

  var poiSource = new VectorSource({
    features: importPOI(),
  });
  var poiLayer = new VectorLayer({
    source: poiSource,
  });

  const drawSource = new VectorSource({ wrapX: false });
  const drawLayer = new VectorLayer({
    source: drawSource,
    style: new Style({
      stroke: new Stroke({
        width: 6,
        color: mainColor,
      }),
    }),
  });

  var positionFeature = new Feature({ name: "Vaša lokacija / Your Location" });
  positionFeature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({
          color: mainColor,
        }),
        stroke: new Stroke({
          color: "#b9d3fc",
          width: 5,
        }),
      }),
    })
  );
  const locationSource = new VectorSource({
    features: [positionFeature],
  });
  const locationLayer = new VectorLayer({
    source: locationSource,
  });

  // Controls
  var attribution = new Attribution({
    collapsible: false,
  });

  var ShareControl = (function (Control) {
    function ShareControl(opt_options) {
      var options = opt_options || {};

      var icon = document.createElement("img");
      icon.src = "./data/qr.svg";

      var button = document.createElement("button");
      button.appendChild(icon);

      var element = document.createElement("div");
      element.className = "share ol-unselectable ol-control";
      element.appendChild(button);

      Control.call(this, {
        element: element,
        target: options.target,
      });

      button.addEventListener("click", this.share.bind(this), false);
    }

    if (Control) ShareControl.__proto__ = Control;
    ShareControl.prototype = Object.create(Control && Control.prototype);
    ShareControl.prototype.constructor = ShareControl;

    ShareControl.prototype.share = function share() {
      const features = drawSource.getFeatures();

      const b64String = exportDraw(features);

      let url = location.protocol + "//" + location.host + location.pathname;
      url += "?d=" + b64String;

      var modal = document.getElementById("share-popup");
      modal.style.display = "block";

      var closeButton = document.getElementById("close-popup");
      closeButton.onclick = function () {
        modal.style.display = "none";
      };

      var sendInput = document.getElementById("send-input");
      sendInput.value = url;

      var sendButton = document.getElementById("send-button");
      sendButton.onclick = function () {
        if (navigator.share) {
          navigator.share({
            url: url,
          });
        } else {
          window.location.href = "mailto:?subject=Digitalni vodič&body=" + url;
        }
      };

      var qrCanvas = document.getElementById("qr-canvas");

      var qrWidth = 460;
      if (screen.width < 500) {
        qrWidth = screen.width -40;
      }

      QRCode.toCanvas(
        qrCanvas,
        url,
        { width: qrWidth, margin: 0 },
        function (error) {
          if (error) {
            console.error(error);
          }
        }
      );
    };

    return ShareControl;
  })(Control);

  var LegendControl = (function (Control) {
    function LegendControl(opt_options) {
      var options = opt_options || {};

      var icon = document.createElement("img");
      icon.src = "./data/lg.svg";

      var button = document.createElement("button");
      button.appendChild(icon);

      var element = document.createElement("div");
      element.className = "legend ol-unselectable ol-control";
      element.appendChild(button);

      Control.call(this, {
        element: element,
        target: options.target,
      });

      button.addEventListener("click", this.openLegend.bind(this), false);
    }

    if (Control) LegendControl.__proto__ = Control;
    LegendControl.prototype = Object.create(Control && Control.prototype);
    LegendControl.prototype.constructor = LegendControl;

    LegendControl.prototype.openLegend = function openLegend() {
      var modal = document.getElementById("legend-popup");
      modal.style.display = "block";

      var closeButton = document.getElementById("close-button");
      closeButton.onclick = function () {
        modal.style.display = "none";
      };
    };

    return LegendControl;
  })(Control);

  // Overlay
  var popupElement = document.getElementById("map-popup");
  var popupBodyElement = document.getElementById("map-popup-body");
  var popupOverlay = new Overlay({
    element: popupElement,
    positioning: "bottom-center",
    stopEvent: false,
    offset: [0, -20],
  });

  // Map
  const map = new Map({
    controls: defaultControls({ attribution: false }).extend([
      attribution,
      new ShareControl(),
      new LegendControl(),
    ]),
    overlays: [popupOverlay],
    target: "map-container",
    layers: [tileLayer, tileCenterLayer, poiLayer, locationLayer, drawLayer],
    view: new View({
      center: fromLonLat([14.51, 46.05]),
      zoom: 15,
      minZoom: 14,
      maxZoom: 18,
      extent: transform([14.45083, 46.02923, 14.56827, 46.1004]),
    }),
  });

  map.on("click", function (evt) {
    if (!drawing) {
      var feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        return feature;
      });
      if (feature) {
        let coordinates = feature.getGeometry().getCoordinates();
        
        // Center map on POI
        map.getView().fit(feature.getGeometry(), {
          padding: [250, 0, 0, 0],
          maxZoom: map.getView().getZoom(),
          duration: 400
        });

        popupOverlay.setPosition(coordinates);

        popupBodyElement.innerHTML = "";

        var nameDiv = document.createElement("div");
        nameDiv.id = "map-popup-body-name";

        var nameSpan = document.createElement("span");
        nameSpan.innerHTML = feature.get("name");
        nameDiv.appendChild(nameSpan);

        if (feature.get("name_en")) {
          var nameEnSpan = document.createElement("span");
          nameEnSpan.className = "en";
          nameEnSpan.innerHTML = " / " + feature.get("name_en");
          nameDiv.appendChild(nameEnSpan);
        }

        popupBodyElement.appendChild(nameDiv);

        if (feature.get("description")) {
          var descDiv = document.createElement("div");
          descDiv.id = "map-popup-body-desc";
          descDiv.innerHTML = feature.get("description");
          popupBodyElement.appendChild(descDiv);
        }

        if (feature.get("description_en")) {
          var descEnDiv = document.createElement("div");
          descEnDiv.id = "map-popup-body-desc-en";
          descEnDiv.innerHTML = feature.get("description_en");
          popupBodyElement.appendChild(descEnDiv);
        }

        popupElement.style.display = "block";
      } else {
        popupElement.style.display = "none";
      }
    }
  });

  // Geolocation
  navigator.geolocation.watchPosition(function (pos) {
    const coords = [pos.coords.longitude, pos.coords.latitude];

    positionFeature.setGeometry(new Point(fromLonLat(coords)));
  });

  // Draw
  var draw;
  var eventPointerId = null;
  var abortedId = null;

  let touchClickOnly = function (mapBrowserEvent) {
    let pointerEvent = mapBrowserEvent.originalEvent;
    const type = pointerEvent.pointerType;
    if (type === "touch") {
      // We check if aborted is 0, because Firefox mobile set ID for each touch.
      if (
        (eventPointerId === null && abortedId !== pointerEvent.pointerId) ||
        abortedId === 0
      ) {
        eventPointerId = pointerEvent.pointerId;
      }

      if (eventPointerId !== pointerEvent.pointerId) {
        return false;
      }
    }
    return true;
  };

  function addInteraction() {
    draw = new Draw({
      source: drawSource,
      type: GeometryType.LINE_STRING,
      freehandCondition: touchClickOnly,
      maxPoints: 500,
      style: new Style({
        stroke: new Stroke({
          width: 6,
          color: mainColor,
        }),
      }),
    });
    draw.on("drawend", function () {
      eventPointerId = null;
    });
    draw.on("drawabort", function () {
      abortedId = eventPointerId;
      eventPointerId = null;
    });
    map.addInteraction(draw);
  }

  function adjustUI() {
    let drawIcon = document.getElementById("drawing-icon");
    let undoButtonWrapper = document.getElementById("undo-wrapper");
    let clearButtonWrapper = document.getElementById("clear-wrapper");

    if (!drawing) {
      drawIcon.src = "./data/pencil.svg";
      undoButtonWrapper.style.display = "none";
      clearButtonWrapper.style.display = "none";
    } else {
      drawIcon.src = "./data/check.svg";
      undoButtonWrapper.style.display = "block";
      clearButtonWrapper.style.display = "block";
    }
  }

  if (drawData) {
    drawSource.addFeatures(importDraw(drawData));
  }

  // Buttons
  var drawButton = document.getElementById("drawing");
  var undoButton = document.getElementById("undo");
  var clearButton = document.getElementById("clear");

  drawButton.onclick = function () {
    if (drawing) {
      drawing = false;

      map.removeInteraction(draw);
    } else {
      drawing = true;

      eventPointerId = null;
      abortedId = null;
      addInteraction();
    }
    adjustUI();
  };

  undoButton.onclick = function () {
    const i = drawSource.getFeatures().length - 1;
    if (i !== -1) {
      drawSource.removeFeature(drawSource.getFeatures()[i]);
    }
  };

  clearButton.onclick = function () {
    const i = drawSource.clear();
  };
}
