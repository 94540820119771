import "./main.css";
import "ol/ol.css";
import { initMap } from "./scripts/map";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const drawData = urlParams.get("d");

window.onload = () => {
  setTimeout(() => {
    var loading = document.getElementById("loading");
    var content = document.getElementById("content");

    loading.style.display = "none";
    content.style.visibility = "visible";
  }, 100);
};

initMap(drawData);
